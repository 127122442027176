import { Locale } from "antd/es/locale";
import { LanguageCode } from "~/types.ts/lang";
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';

export const getAntdLanguage = (languageCode?: LanguageCode): Locale => {
  switch (languageCode) {
    case LanguageCode.en_US:
      return enUS;
    case LanguageCode.zh_CN:
        return zhCN;
    default:
      return zhCN;
  }
};

// 语言列表
export const langList = [
  {
    label: "简体中文",
    key: LanguageCode.zh_CN,
  },
  {
    label: "English",
    key: LanguageCode.en_US,
  },
];
