import React from "react";
import ReactDOM from "react-dom";

import { Item, ModalProps, ModalsState } from "./types";
import { MODAL_CONTAINER } from "~/constans/modal";

// /**
//  * 目前项目中很多地方的弹框在页面初始化时就直接渲染出来了。但是用户的使用是延后的，或者并不会使用。
//  * 同时在关闭弹窗时还是需要手动清理状态以便下一次使用
//  * ##
//  * 针对上面的情况这里使用一个全局的组件统一处理。
//  * 1: 在使用时才渲染组件
//  * 2: 组件关闭后销毁组件，下次打开重新渲染，避免状态没有清楚造成的影响
//  * 3: Modals 只会全局存在一个
//  */

let seed = 0;

function genModalId(): string {
  return `model-${++seed}`;
}

const getItemPropsFromModal = (params: {
  id: string;
  resolve: (value: any | PromiseLike<any>) => void;
  props?: ModalProps;
  open?: boolean;
}): Item["props"] => {
  const { onOk, onCancel, ...rest } = params.props || {};
  const open = params.open ?? true;
  return {
    ...rest,
    visible: open, // 直接显示组件
    open: open, // antd v4 废弃visible，改为open属性
    onOk: (data) => {
      onOk?.(data);
      Modals.Install?.remove(params.id);
      params.resolve(data);
    },
    onCancel: (data) => {
      onCancel?.(data);
      Modals.Install?.remove(params.id);
      params.resolve(data);
    },
  };
};

class Modals extends React.PureComponent<any, ModalsState> {
  static Install?: Modals = undefined;

  /**
   *
   * @param {*} component : modal组件
   * @param {*} props : props
   * @param {*} modalId : 用户手动指定弹框的Id否则默认生成一个
   * @returns 返回Promise以支持async/await的使用
   */
  static show(
    component: React.ElementType,
    props?: ModalProps,
    modalId?: string
  ): Promise<any> {
    return new Promise((resolve) => {
      const id = modalId || genModalId();
      const item: Item = {
        id,
        Comp: component, // 组件
        props: getItemPropsFromModal({
          id,
          resolve,
          props,
          open: true,
        }),
      };
      Modals.Install?.setState({
        list: [...Modals.Install.state.list, item],
      });
    });
  }

  // 组件是否存在
  static has(modalId: string): boolean {
    if (modalId && Modals.Install) {
      return Modals.Install.state.list.some(
        (modal: Item) => modal.id === modalId
      );
    }
    return false;
  }

  // 已经显示的弹框更新props
  static updateProps(modalId: string, props: ModalProps): void {
    if (modalId && Modals.Install) {
      const index = Modals.Install.state.list.findIndex(
        (modal: Item) => modal.id === modalId
      );
      if (index !== -1) {
        const newList: Item[] = [...Modals.Install.state.list];
        const { open, resolve } = newList[index].props;
        newList[index] = {
          ...newList[index],
          props: getItemPropsFromModal({
            id: modalId,
            resolve: resolve,
            props: props,
            open: open, // 使用弹框当前的open状态
          }),
        };
        Modals.Install.setState({
          list: newList,
        });
      }
    }
  }

  static delete(modalId: string): void {
    if (modalId && Modals.Install) {
      const newList = Modals.Install.state.list.filter(
        (modal: Item) => modal.id === modalId
      );
      Modals.Install?.setState({
        list: [...newList],
      });
    }
  }

  static clear(): void {
    if (Modals.Install) {
      Modals.Install?.setState({
        list: [],
      });
    }
  }

  state = {
    list: [],
    loaded: false,
  };

  constructor(props: any) {
    super(props);

    if (Modals.Install) {
      return Modals.Install;
    }
    Modals.Install = this;
  }

  componentDidMount(): void {
    this.setState({
      loaded: true,
    });
  }

  remove = (compId: string): void => {
    Promise.resolve().then(() => {
      this.setState({
        list: this.state.list.filter((item: Item) => item.id !== compId),
      });
    });
  };

  renderList = () => {
    return this.state.list.map((item: Item) => {
      const { id, Comp, props } = item;
      return (
        <div key={id} id={id}>
          <Comp {...props} getContainer={(): HTMLElement | null => document.getElementById(id)} />
        </div>
      );
    });
  };

  getContainer = () => {
    return document.getElementById(MODAL_CONTAINER) as HTMLElement;
  };

  render() {
    return this.state.loaded
      ? ReactDOM.createPortal(this.renderList(), this.getContainer())
      : null;
  }
}
export default Modals;
