import "~/global/styles/index.scss";
import { Suspense } from "react";
import { ConfigProvider, App } from "antd";
import Loading from "./components/Loading";
import Routes from "./routes";
import { MODAL_CONTAINER } from "./constans/modal";
import { AuthProvider } from "./context";
import { getAntdLanguage } from "./utils/lang";
import { LanguageCode } from "./types.ts/lang";
import { Provider } from "jotai";
import userStore from "./store/userStore";
import "./App.css";

function MyApp() {
  return (
    <ConfigProvider
      locale={getAntdLanguage(LanguageCode.zh_CN as LanguageCode)}
      theme={{
        token: {
          colorPrimary: "#499BE9",
        },
        components: {
          Button: {
            borderRadius: 16,
            borderRadiusLG: 20,
            borderRadiusSM: 12,
          },
        },
      }}
    >
      <App>
        <Provider store={userStore}>
          <AuthProvider value={null}>
            <Suspense
              fallback={
                <div className="app-loading">
                  <Loading loading={true} />
                </div>
              }
            >
              <>
                <Routes />
                <div id={MODAL_CONTAINER}></div>
              </>
            </Suspense>
          </AuthProvider>
        </Provider>
      </App>
    </ConfigProvider>
  );
}

export default MyApp;
