
import { MyRouteObject } from "./types";

// const LoginPage = lazy(
//   () => import(/* webpackChunkName: "logo" */ "~/pages/user/login")
// );

const withoutRoutes: MyRouteObject[] = [
  {
    path: "/login",
    // element: <LoginPage />,
    name: "登录",
  },
];

export default withoutRoutes;
