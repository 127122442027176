export enum LanguageCode {
    /**
     * 英语
     */
    en_US = "en-US",
    /**
     * 中文
     */
    zh_CN = "zh-CN",
  }
  