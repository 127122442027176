import { Spin } from "antd";
import classNames from "classnames";
import { FC } from "react";

import { LoadingProps } from "./types";
import style from "./style.module.scss";

const Loading: FC<LoadingProps> = (props): JSX.Element => {
  const { loading, children, className, ...reset } = props;
  return (
    <>
      {loading ? (
        <div className={classNames(style.loading, className)}>
          <Spin spinning={loading} {...reset} />
        </div>
      ) : typeof children === "function" ? (
        children()
      ) : (
        children
      )}
    </>
  );
};
export default Loading;
