// InitLoader 用于获取账号信息
import { FC, memo, useEffect, useState } from "react";
// import { useSearchParams } from "react-router-dom";


import { InitializeLoaderProps } from "./types";
import Loading from "../Loading";

const InitializeLoader: FC<InitializeLoaderProps> = (props): JSX.Element | null => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [initialized, setInitialized] = useState<boolean>(false);


  useEffect(() => {
    setInitialized(true);
  }, [])

  return initialized ? props.children() : <Loading loading className="h-full" />;
};

export default memo(InitializeLoader);
