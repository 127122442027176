import { memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import { matchRoutes, useLocation } from "react-router-dom";

import { routeList } from "~/routes";
import { MyRouteObject } from "~/routes/types";

const HelmetContainer = (props: { children: React.ReactNode }): JSX.Element => {
  const location = useLocation();
  const routes = useMemo(() => matchRoutes(routeList, location) || [], [location]);

  // 从后向前找第一个含有name数据的路由
  const currentRoute = routes.reverse().find((route) => !!(route.route as MyRouteObject)?.name);

  const titleArr = ["竹间简历"];
  if (currentRoute) {
    titleArr.push((currentRoute.route as MyRouteObject).name || "");
  }

  return (
    <>
      <Helmet>
        <title>{titleArr.join(" - ")}</title>
      </Helmet>
      {props.children}
    </>
  );
};

export default memo(HelmetContainer);
