import { lazy } from "react";
// import { Navigate } from "react-router-dom";

import InitializeLoader from "~/components/InitializeLoader";

import { MyRouteObject } from "./types";
import { Navigate } from "react-router-dom";

const Layout = lazy(
  () => import( "~/components/Layout")
);
const HomePage = lazy(
  () => import( "~/pages/home")
);
const ResumesPage = lazy(
  () => import( "~/pages/resumes")
);
const ResumePage = lazy(
  () => import( "~/pages/resume")
);
const ThemesPage = lazy(
  () => import( "~/pages/themes/list")
);
const ThemeDetailPage = lazy(
  () => import( "~/pages/themes/detail")
);

const mainRoutes: MyRouteObject[] = [
  {
    element: (
      <InitializeLoader>{(): JSX.Element => <Layout />}</InitializeLoader>
    ),
    children: [
      {
        index: true,
        path: "/",
        element: <HomePage />,
        name: "首页",
      },
      {
        path: "/themes",
        element: <ThemesPage />,
        name: "模版中心",
      },
      {
        path: "/themes/:id",
        element: <ThemeDetailPage />,
        name: "模版中心",
      },
      {
        path: "/resumes",
        element: <ResumesPage />,
        name: "我的简历",
      },
      {
        element: <Navigate to="/" replace={true} />,
      },
    ],
  },
  {
    path: "/resume/:mode/:id",
    element: <ResumePage />,
    name: "编辑页",
  },
  {
    element: <Navigate to="/resumes" replace={true} />,
  },
];

export default mainRoutes;
