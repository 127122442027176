import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from "react-router-dom";

import HelmetContainer from "~/components/HelmetContainer";

import withoutRoutes from "./WithoutAuthRoutes";
import mainRoutes from "./mainRoutes";
import Modals from "~/common/components/Modals";

export const routeList = [
  {
    element: (
      <HelmetContainer>
        <Outlet />
        <Modals />
      </HelmetContainer>
    ),
    children: [...withoutRoutes, ...mainRoutes],
  },
] as RouteObject[];

const router = createBrowserRouter(routeList);

const Route = (): JSX.Element => <RouterProvider router={router} />;

export default Route;
